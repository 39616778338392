import { FC } from 'react';

import { HTTPS } from 'constants/general/secureProtocol';
import { useQrCode } from 'hooks/useQrCode';
import { QrCode } from 'components/QrCode';

interface IProjectInfoProps {
	projectName: string;
	shortUrlCode: string;
	shortUrlDomain: string;
	projectUniqueId: string;
}

export const ProjectInfo: FC<IProjectInfoProps> = ({
	projectName,
	shortUrlCode,
	shortUrlDomain,
	projectUniqueId,
}) => {
	const qrUrl = useQrCode(`${HTTPS}${shortUrlDomain}/${shortUrlCode}`);

	return (
		<div className="project-details-form">
			<div>
				<div className="org-code-form">
					<span className="label">accessmyevent.com code:</span>
					<span className="org-code-link">
						{shortUrlDomain}/<span className="org-code">{shortUrlCode}</span>
					</span>
				</div>
				<div className="org-code-form">
					<span className="label">Project ID:</span>
					<span className="org-code-link">
						<span className="org-code">{projectUniqueId}</span>
					</span>
				</div>
			</div>
			<QrCode qrUrl={qrUrl} label="Project QR Code" fileName={projectName} />
		</div>
	);
};
