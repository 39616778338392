import { object, Schema, string } from 'yup';

import { emailRequiredSchema } from 'constants/general/validation/schemes/email';
import {
	requiredStringSchema,
	mediumLengthStringSchema,
	largeLengthRequiredStringSchema,
	mediumLengthRequiredStringSchema,
} from 'constants/general/validation/schemes/string';

import { IBillingInfoFormValues } from './types';

const billingPhoneFieldValidation = object().shape({
	firstPartPhone: requiredStringSchema,
	secondPartPhone: requiredStringSchema,
	thirdPartPhone: requiredStringSchema,
});

const ssnFieldValidation = object().shape({
	firstPartSsn: string(),
	secondPartSsn: string(),
	thirdPartSsn: string(),
});

const einFieldValidation = object().shape({
	firstPartEin: string(),
	secondPartEin: string(),
});

const tinFieldValidation = object()
	.shape({
		ssn: ssnFieldValidation,
		ein: einFieldValidation,
	})
	.test('tin', '', (values, context) => {
		const { firstPartSsn, secondPartSsn, thirdPartSsn } = values.ssn;
		const { firstPartEin, secondPartEin } = values.ein;

		const isSsnEmpty = !firstPartSsn && !secondPartSsn && !thirdPartSsn;
		const isEinEmpty = !firstPartEin && !secondPartEin;

		if (isSsnEmpty && isEinEmpty) {
			return context.createError({
				path: 'tin.ssn.firstPartSsn',
				message: 'Employer identification number is required',
			});
		}

		return true;
	});

export const w9InfoValidationSchema = object().shape<
	Partial<Record<keyof IBillingInfoFormValues, Schema>>
>({
	billingFax: mediumLengthStringSchema,
	billingPhoneExt: mediumLengthStringSchema,
	billingFirstName: largeLengthRequiredStringSchema,
	billingLastName: largeLengthRequiredStringSchema,
	billingBusinessName: largeLengthRequiredStringSchema,
	billingStreet: largeLengthRequiredStringSchema,
	billingCity: mediumLengthRequiredStringSchema,
	billingState: requiredStringSchema,
	billingPostalCode: requiredStringSchema,
	billingPhone: billingPhoneFieldValidation,
	billingEmail: emailRequiredSchema,
	billingEntityType: requiredStringSchema,
	tin: tinFieldValidation,
	billingW9Initials: mediumLengthRequiredStringSchema,
});
