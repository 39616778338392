import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { FC, RefObject, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { object, lazy } from 'yup';

import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';
import { journeyTimelines } from 'constants/projects/timeline/journeyTimelines';
import { projectJourneyInfo } from 'constants/projects/projectJourneyInfo';
import { ROUTES } from 'constants/ROUTES';

import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import { ProjectTypes } from 'api/models/requests/projects/projectTypes';
import ProjectsService from 'api/services/ProjectsService';
import {
	IPriceListSaleParams,
	ICreateBlankProjectBody,
} from 'api/models/requests/projects/createBlankProject';

import { getProcessedErrors } from 'utils/errors/getProcessedErrors';
import { prepareDate } from 'utils/dates/setCurrentTimeToDate';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { useOrganizationKeyExist } from 'pages/Projects/hooks/useOrganizationKeyExist';
import {
	saleTypesSelector,
	setCreatedProject,
	organizationKeySelector,
	setShowSuccessCreateProject,
	subjectFormQuestionsParamsSelector,
	isValidSubjectFormQuestionsSelector,
} from 'store/projects';

import { BlankProjectStep1 } from './BlankProjectStep1';
import { BlankProjectStep2 } from './BlankProjectStep2';
import { Steps } from '../../types';
import {
	createBlankProjectStep1Scheme,
	getCreateBlankProjectStep2Scheme,
} from './validation';

export interface ICreateBlankProjectValues {
	projectUrl: string;
	projectName: string;
	projectGreeting: string;
	expireDate: Date | null;
	publishDate: Date | null;
	journey: ProjectJourneys;
	accessCodeMessage: string;
	smsSpecialMessage: string;
	emailSpecialMessage: string;
	isMarketingEnabled: boolean;
	lastPictureDate: Date | null;
	priceListSale1: string | null;
	priceListSale2: string | null;
	priceListSale3: string | null;
	firstPictureDate: Date | null;
	orderNotificationEmail: string;
	leadCampaignKey: number | null;
	buyerCampaignKey: number | null;
	orderConfirmationMessage: string;
	projectType: ProjectTypes | null;
	estimatedNumberOfSubjects: string;
	prospectCampaignKey: number | null;
}

export const initialValues: ICreateBlankProjectValues = {
	projectUrl: '',
	projectName: '',
	expireDate: null,
	projectType: null,
	publishDate: null,
	projectGreeting: '',
	priceListSale1: null,
	priceListSale2: null,
	priceListSale3: null,
	lastPictureDate: null,
	accessCodeMessage: '',
	smsSpecialMessage: '',
	leadCampaignKey: null,
	buyerCampaignKey: null,
	firstPictureDate: null,
	emailSpecialMessage: '',
	isMarketingEnabled: true,
	prospectCampaignKey: null,
	orderNotificationEmail: '',
	journey: ProjectJourneys.A,
	orderConfirmationMessage: '',
	estimatedNumberOfSubjects: '',
};

export type ScrollHelperRef = RefObject<HTMLDivElement>;

export const CreateBlankProject: FC = () => {
	const [step, setStep] = useState<Steps>(Steps.STEP1);

	const editorRef = useRef<CKEditor<Editor>>(null);

	const [saleType1, saleType2, saleType3] = useAppSelector(saleTypesSelector);
	const organizationKey = useAppSelector(organizationKeySelector);

	const isValidSubjectFormQuestions = useAppSelector(
		isValidSubjectFormQuestionsSelector
	);
	const subjectFormQuestionsParams = useAppSelector(
		subjectFormQuestionsParamsSelector
	);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useOrganizationKeyExist();

	const onSubmit = async (
		values: ICreateBlankProjectValues,
		{ setErrors }: FormikHelpers<ICreateBlankProjectValues>
	) => {
		const {
			journey,
			projectUrl,
			expireDate,
			projectType,
			projectName,
			publishDate,
			priceListSale1,
			priceListSale2,
			priceListSale3,
			leadCampaignKey,
			lastPictureDate,
			buyerCampaignKey,
			firstPictureDate,
			smsSpecialMessage,
			accessCodeMessage,
			isMarketingEnabled,
			emailSpecialMessage,
			prospectCampaignKey,
			orderNotificationEmail,
			orderConfirmationMessage,
			estimatedNumberOfSubjects,
		} = values;

		if (!isValidSubjectFormQuestions || !projectType) return;

		const currentEditorValue = editorRef.current?.editor?.data.get();

		const priceLists: IPriceListSaleParams[] = [];

		const extractPriceListData = (priceListSale: string) => {
			const [key, fulfillment] = priceListSale.split('-');

			const keyNumber = +key;

			return {
				priceListKey: keyNumber ? keyNumber : null,
				fulfillmentType: fulfillment as PriceListFulfillment,
			};
		};

		if (priceListSale1) {
			priceLists.push({
				saleType: saleType1,
				...extractPriceListData(priceListSale1),
			});
		}

		if (priceListSale2) {
			priceLists.push({
				saleType: saleType2,
				...extractPriceListData(priceListSale2),
			});
		}

		if (priceListSale3) {
			priceLists.push({
				saleType: saleType3,
				...extractPriceListData(priceListSale3),
			});
		}

		const expireDateParam = expireDate ? prepareDate(expireDate) : '';
		const publishDateParam = publishDate ? prepareDate(publishDate) : '';
		const lastPictureDateParam = lastPictureDate
			? prepareDate(lastPictureDate)
			: '';
		const firstPictureDateParam = firstPictureDate
			? prepareDate(firstPictureDate)
			: '';

		const createProjectBody: ICreateBlankProjectBody = {
			journey,
			priceLists,
			projectUrl,
			projectType,
			projectName,
			organizationKey,
			isMarketingEnabled,
			orderNotificationEmail,
			orderConfirmationMessage,
			expireDate: expireDateParam,
			publishDate: publishDateParam,
			lastPictureDate: lastPictureDateParam,
			firstPictureDate: firstPictureDateParam,
			projectGreeting: currentEditorValue || '',
			subjectFormQuestions: subjectFormQuestionsParams,
			estimatedNumberOfSubjects: +estimatedNumberOfSubjects,
			leadCampaignKey: isMarketingEnabled ? leadCampaignKey : null,
			buyerCampaignKey: isMarketingEnabled ? buyerCampaignKey : null,
			smsSpecialMessage: isMarketingEnabled ? smsSpecialMessage : null,
			accessCodeMessage: isMarketingEnabled ? accessCodeMessage : null,
			emailSpecialMessage: isMarketingEnabled ? emailSpecialMessage : null,
			prospectCampaignKey: isMarketingEnabled ? prospectCampaignKey : null,
		};

		try {
			const data = await ProjectsService.createBlankProject(createProjectBody);

			dispatch(setCreatedProject(data));
			dispatch(setShowSuccessCreateProject(true));

			navigate(`${ROUTES.PROJECTS.PROJECTS}/${data.projectKey}`);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const processedErrors = getProcessedErrors(values, errors);

			setErrors(processedErrors);
		}
	};

	const lazyValidation =
		step === Steps.STEP1
			? object(createBlankProjectStep1Scheme)
			: lazy((values: ICreateBlankProjectValues) => {
					const { journey, isMarketingEnabled } = values;

					const { journeyDate } = journeyTimelines[journey];

					const {
						validatePriceListSale1,
						validatePriceListSale2,
						validatePriceListSale3,
					} = projectJourneyInfo[journey];

					return object({
						...createBlankProjectStep1Scheme,
						...getCreateBlankProjectStep2Scheme({
							isMarketingEnabled,
							validatePriceListSale1,
							validatePriceListSale2,
							validatePriceListSale3,
							datesLabels: journeyDate.datesLabels,
							validateLastPictureDate:
								journeyDate.validateBlankProjectLastPictureDate,
							validateFirstPictureDate:
								journeyDate.validateBlankProjectFirstPictureDate,
						}),
					});
			  });

	return (
		<Formik
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={lazyValidation}
		>
			<>
				{step === Steps.STEP1 && <BlankProjectStep1 setStep={setStep} />}
				{step === Steps.STEP2 && (
					<BlankProjectStep2 setStep={setStep} editorRef={editorRef} />
				)}
			</>
		</Formik>
	);
};
