import { ActionMeta, SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { ChangeEvent, FC } from 'react';

import { projectJourneysSelectOptions } from 'constants/projects/selectOptions/projectJourneysSelectOptions';
import { projectTypesSelectOptions } from 'constants/projects/selectOptions/projectTypesSelectOptions';
import { commaRegEx } from 'constants/projects/validation/estimatedSubjectNumber';

import { useProjectTypeSubjects } from 'pages/Projects/hooks/useProjectTypeSubjects';
import { ICreateBlankProjectValues } from 'pages/Projects/pages/CreateBlankProject';
import { IStepControl, Steps } from 'pages/Projects/types';

import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import ProjectsService from 'api/services/ProjectsService';

import { getProcessedErrors } from 'utils/errors/getProcessedErrors';
import { normalizeDigit } from 'utils/ui/normalizeDigit';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { ISelectOption } from 'types/ui/select';

export const ProjectBlankForm: FC<IStepControl> = ({ setStep }) => {
	const {
		errors,
		values,
		touched,
		setErrors,
		setTouched,
		handleChange,
		validateForm,
		setFieldValue,
	} = useFormikContext<ICreateBlankProjectValues>();

	const { subjects } = useProjectTypeSubjects(values.projectType);

	const { projectUrl, projectName, estimatedNumberOfSubjects } = values;

	const handleContinue = () => {
		validateForm(values)
			.then((data) => {
				void setTouched({
					journey: true,
					projectUrl: true,
					projectName: true,
					projectType: true,
					estimatedNumberOfSubjects: true,
				});

				const isValidationError = Object.values(data).some(
					(error) => error.length
				);

				if (isValidationError) return;

				return ProjectsService.validateNameUrl({
					projectUrl,
					projectName,
					estimatedNumberOfSubjects: +estimatedNumberOfSubjects,
				});
			})
			.then((data) => {
				if (!data) return;

				setStep(Steps.STEP2);
			})
			.catch((error: IErrorResponse) => {
				const processedErrors = getProcessedErrors(values, error.errors);

				setErrors(processedErrors);
			});
	};

	const handleChangeSelect = <T,>(
		option: SingleValue<ISelectOption<T>>,
		actionMeta?: ActionMeta<ISelectOption<T>>
	) => {
		if (!option || !actionMeta?.name) return;

		void setFieldValue(actionMeta.name, option.value);
	};

	const handleChangeEstimatedNumber = (e: ChangeEvent<HTMLInputElement>) => {
		const { id, value } = e.target;

		const purifiedValue = value.replace(commaRegEx, '');

		if (isNaN(+purifiedValue)) return;

		void setFieldValue(id, purifiedValue);
	};

	const estimatedNumberValue =
		(estimatedNumberOfSubjects &&
			normalizeDigit({
				value: +estimatedNumberOfSubjects.replace(commaRegEx, ''),
			})) ||
		'';

	return (
		<form className="project-blank-form">
			<InputGrid
				required
				id="projectName"
				label="Project Name"
				placeholder="Project name"
				value={values.projectName}
				error={errors.projectName}
				handleChange={handleChange}
				touched={touched.projectName}
				supLabel="visible to consumers"
			/>
			<InputGrid
				required
				id="projectUrl"
				label="Project URL"
				placeholder="Project URL"
				value={values.projectUrl}
				error={errors.projectUrl}
				handleChange={handleChange}
				touched={touched.projectUrl}
				supLabel="schoolhouse.accessmyevent.com/"
			/>
			<div className="input-form-grid">
				<SelectComponent
					id="projectType"
					name="projectType"
					label="Project Type"
					error={errors.projectType}
					className="select-required"
					value={values.projectType}
					touched={touched.projectType}
					onChange={handleChangeSelect}
					selectPlaceholder="Project type"
					selectOptions={projectTypesSelectOptions}
				/>
			</div>
			<InputGrid
				required
				value={estimatedNumberValue}
				id="estimatedNumberOfSubjects"
				label={`Estimated # of ${subjects}`}
				error={errors.estimatedNumberOfSubjects}
				handleChange={handleChangeEstimatedNumber}
				touched={touched.estimatedNumberOfSubjects}
				placeholder={`# of ${subjects.toLowerCase()}`}
			/>
			<div className="input-form-grid">
				<SelectComponent
					id="journey"
					name="journey"
					error={errors.journey}
					value={values.journey}
					label="Project Journey"
					touched={touched.journey}
					className="select-required"
					onChange={handleChangeSelect}
					selectPlaceholder="Project Journey"
					selectOptions={projectJourneysSelectOptions}
				/>
				<span className="sublabel-grid">This cannot be changed later</span>
			</div>
			<Button
				value="Continue"
				className="btn-primary"
				handleClick={handleContinue}
			/>
		</form>
	);
};
