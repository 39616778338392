import { object } from 'yup';

import { invalidCharactersMessage } from 'constants/general/validation/generalMessages';
import { requiredStringSchema } from 'constants/general/validation/schemes/string';
import { nameValidationMessage } from 'constants/organizations/validation';
import { invalidZipMessage } from 'constants/general/validation/zip';
import { VALID_FIELDS_LENGTH } from 'constants/auth/validation';
import {
	alphaNumericWithDashRegex,
	baseNameWithSpecialSymbolsRegex,
} from 'constants/general/validation/generalRegEx';
import {
	maxNetworkDomainLength,
	networkDomainLengthError,
} from 'constants/studio/validation/networkDomain';
import {
	maxStudioNameLength,
	studioNameLengthError,
} from 'constants/studio/validation/studioName';

export const updateCardValidationSchema = object({
	cardType: requiredStringSchema,
	cardNumber: requiredStringSchema,
	expiration: requiredStringSchema,
	verificationCode: requiredStringSchema,
	billingZip: requiredStringSchema.min(
		VALID_FIELDS_LENGTH.ZIP,
		invalidZipMessage
	),
});

export const createStudioValidationSchema = object({
	...updateCardValidationSchema.fields,

	studioName: requiredStringSchema
		.max(maxStudioNameLength, studioNameLengthError)
		.matches(baseNameWithSpecialSymbolsRegex, nameValidationMessage),

	phone: requiredStringSchema,
	street: requiredStringSchema,
	city: requiredStringSchema,
	zip: requiredStringSchema,
	state: requiredStringSchema,

	networkDomain: requiredStringSchema
		.max(maxNetworkDomainLength, networkDomainLengthError)
		.matches(alphaNumericWithDashRegex, invalidCharactersMessage),
});
