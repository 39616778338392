import { object, Schema, string, ref } from 'yup';

import { emailRequiredSchema } from 'constants/general/validation/schemes/email';

import { ISendVerificationCodeValues } from '.';

export const sendVerificationCodeSchema = object().shape<
	Partial<Record<keyof ISendVerificationCodeValues, Schema>>
>({
	email: emailRequiredSchema,
	confirmEmail: string().oneOf([ref('email')], 'Emails must match'),
});
