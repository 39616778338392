import { string } from 'yup';

import { getFieldLengthMessage } from 'utils/validations/general/getFieldLengthMessage';

import { requiredFieldMessage } from '../generalMessages';
import { FIELDS_LENGTH } from '../fieldsLength';

export const requiredStringSchema = string().required(requiredFieldMessage);

export const smallLengthStringSchema = string().max(
	FIELDS_LENGTH.SMALL_LENGTH,
	getFieldLengthMessage(FIELDS_LENGTH.SMALL_LENGTH)
);

export const smallLengthRequiredStringSchema =
	smallLengthStringSchema.concat(requiredStringSchema);

export const mediumLengthStringSchema = string().max(
	FIELDS_LENGTH.MEDIUM_LENGTH,
	getFieldLengthMessage(FIELDS_LENGTH.MEDIUM_LENGTH)
);

export const mediumLengthRequiredStringSchema =
	mediumLengthStringSchema.concat(requiredStringSchema);

export const largeLengthStringSchema = string().max(
	FIELDS_LENGTH.LARGE_LENGTH,
	getFieldLengthMessage(FIELDS_LENGTH.LARGE_LENGTH)
);

export const largeLengthRequiredStringSchema =
	largeLengthStringSchema.concat(requiredStringSchema);
