import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FC, useEffect } from 'react';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { comingSoonSelector } from 'store/dashboard';
import { getComingSoonAsync } from 'store/projects';

import { Table } from 'components/Table';
import { Card } from 'components/Card';

import { IComingSoon } from 'api/models/responses/projects/comingSoon';
import { normalizeDate } from 'utils/ui/normalizeDate';
import { ROUTES } from 'constants/ROUTES';

export const ComingSoon: FC = () => {
	const comingSoon = useAppSelector(comingSoonSelector);

	const dispatch = useAppDispatch();

	useEffect(() => {
		void dispatch(getComingSoonAsync());
	}, []);

	const columns: Column<IComingSoon>[] = [
		{
			Header: 'Project Name',
			Cell: ({ row }: CellProps<IComingSoon>) => {
				const { projectKey, projectName } = row.original;

				return (
					<Link
						title={projectName}
						className="card-table-link"
						to={`${ROUTES.PROJECTS.PROJECTS}/${projectKey}`}
					>
						{projectName}
					</Link>
				);
			},
		},
		{
			Header: 'Sale',
			accessor: 'sale',
		},
		{
			Header: 'Photo Day',
			accessor: 'photoDay',
			Cell: ({ value }: CellProps<IComingSoon, IComingSoon['photoDay']>) => (
				<>{normalizeDate(value)}</>
			),
		},
		{
			Header: 'Sale End',
			accessor: 'saleEnds',
			Cell: ({ value }: CellProps<IComingSoon, IComingSoon['saleEnds']>) => (
				<>{normalizeDate(value)}</>
			),
		},
	];

	return (
		<Card
			title="Coming Soon"
			bodyClassName="card-table"
			subtitle="(events in the next 7 days)"
		>
			<Table<IComingSoon> columns={columns} data={comingSoon} />
		</Card>
	);
};
