import { number, object, Schema, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import { emailSchema } from 'constants/general/validation/schemes/email';

import { ISendLinkBody } from 'api/models/requests/dashboard/sendLink';

const emailAndPhoneMessage =
	'Please provide either an email address or a mobile phone number.';

export const sendLinkValidationSchema = object().shape<
	Partial<Record<keyof ISendLinkBody, Schema>>
>(
	{
		projectKey: number().required(requiredFieldMessage),
		emailAddress: emailSchema.when('mobilePhoneNumber', {
			is: (value: string) => !value?.length,
			then: () => emailSchema.concat(string().required(emailAndPhoneMessage)),
		}),
		mobilePhoneNumber: string().when('emailAddress', {
			is: (value: string) => !value?.length,
			then: () => string().required(emailAndPhoneMessage),
		}),
	},
	[['emailAddress', 'mobilePhoneNumber']]
);
