import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { ChangeEvent, FC } from 'react';

import { validateExpirationDate } from 'utils/validations/general/validateExpirationDate';
import { validateStringNumber } from 'utils/validations/general/validateStringNumber';

import { paymentsSelectOptions } from 'constants/payments/paymentsSelectOptions';
import { VALID_FIELDS_LENGTH } from 'constants/auth/validation';

import { SelectComponent } from 'components/FormControls/Select';
import { InputField } from 'components/InputField';

import { ISelectOption } from 'types/ui/select';
import { Payments } from 'types/ui/payments';

import { usePasswordInput } from 'hooks/formControls/usePasswordInput';

import { ICreateStudioValues } from '../..';

export const BillingInformation: FC = () => {
	const { values, errors, touched, handleChange, setFieldValue } =
		useFormikContext<ICreateStudioValues>();

	const cardNumberVisibility = usePasswordInput();
	const cvvVisibility = usePasswordInput();

	const handleExpirationChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		const prevValue = values.expiration;

		const validatedExpirationDate = validateExpirationDate(prevValue, value);

		void setFieldValue('expiration', validatedExpirationDate);
	};

	const handleCardTypeChange = (
		option: SingleValue<ISelectOption<Payments>>
	) => {
		if (!option) return;

		void setFieldValue('cardType', option.value);

		void setFieldValue('cardNumber', '');
		void setFieldValue('expiration', '');
		void setFieldValue('billingZip', '');
		void setFieldValue('verificationCode', '');
	};

	const handleCvvChange = (e: ChangeEvent<HTMLInputElement>) => {
		const validCvvLength =
			values.cardType === Payments.AMERICAN_EXPRESS
				? VALID_FIELDS_LENGTH.UNIQ_CVV
				: VALID_FIELDS_LENGTH.CVV;

		const isValidCvv = validateStringNumber(e.target.value, validCvvLength);

		if (!isValidCvv) return;

		handleChange(e);
	};

	const handleZipChange = (e: ChangeEvent<HTMLInputElement>) => {
		const isValidZip = validateStringNumber(
			e.target.value,
			VALID_FIELDS_LENGTH.ZIP
		);

		if (!isValidZip) return;

		handleChange(e);
	};

	const handleCardNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
		const isValidCardNumber = validateStringNumber(
			e.target.value,
			VALID_FIELDS_LENGTH.CARD_NUMBER
		);

		if (!isValidCardNumber) return;

		handleChange(e);
	};

	return (
		<div className="studio-form">
			<h4 className="studio-subtitle">
				This is the card we will use for any fees and balances for this studio.
				We will charge $1 to this card to validate it can be used.
			</h4>
			<div className="form-container">
				<SelectComponent
					id="cardType"
					label="Card Type"
					value={values.cardType}
					error={errors.cardType}
					touched={touched.cardType}
					selectPlaceholder="Card Type"
					onChange={handleCardTypeChange}
					selectOptions={paymentsSelectOptions}
				/>
			</div>
			<div className="acc-toggle-container">
				<InputField
					id="cardNumber"
					label="Card Number"
					value={values.cardNumber}
					placeholder="Card Number"
					error={errors.cardNumber}
					touched={touched.cardNumber}
					wrapperClassName="form-container"
					handleChange={handleCardNumberChange}
					type={cardNumberVisibility.inputType}
				/>
				<span
					className="acc-toggle-btn"
					onClick={cardNumberVisibility.handleShowValue}
				>
					{cardNumberVisibility.toggleValue}
				</span>
			</div>
			<InputField
				id="expiration"
				label="Expiration"
				placeholder="MM/YY"
				value={values.expiration}
				error={errors.expiration}
				touched={touched.expiration}
				wrapperClassName="form-container"
				handleChange={handleExpirationChange}
			/>
			<div className="form-col-container">
				<div className="acc-toggle-section">
					<InputField
						label="CVV"
						id="verificationCode"
						placeholder="CVV Code"
						wrapperClassName="form-col"
						type={cvvVisibility.inputType}
						handleChange={handleCvvChange}
						value={values.verificationCode}
						error={errors.verificationCode}
						touched={touched.verificationCode}
					/>
					<span
						className="acc-toggle-btn"
						onClick={cvvVisibility.handleShowValue}
					>
						{cvvVisibility.toggleValue}
					</span>
				</div>
				<InputField
					id="billingZip"
					placeholder="Zipcode"
					label="Billing Zipcode"
					value={values.billingZip}
					error={errors.billingZip}
					wrapperClassName="form-col"
					touched={touched.billingZip}
					handleChange={handleZipChange}
				/>
			</div>
		</div>
	);
};
