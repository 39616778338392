import { useEffect } from 'react';

export const useRemoveButtonFocus = () => {
	useEffect(() => {
		const handleButtonClick = (event: Event) => {
			const target = event.target as HTMLButtonElement;

			if (target.tagName === 'BUTTON') {
				setTimeout(() => {
					target.blur();
				}, 300);
			}
		};

		document.addEventListener('click', handleButtonClick);

		return () => {
			document.removeEventListener('click', handleButtonClick);
		};
	}, []);
};
