import { useFormikContext } from 'formik';
import { FC, useRef } from 'react';

import { Button } from 'components/FormControls/Button';
import { Modal, ModalRef } from 'components/Modal';

import { validateSubjectFromQuestions } from 'store/projects';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { IStepControl, Steps } from 'pages/Projects/types';

import { ICreateBlankProjectValues, initialValues } from '../../..';

export const StepControls: FC<IStepControl> = ({ setStep }) => {
	const { values, resetForm, handleSubmit } =
		useFormikContext<ICreateBlankProjectValues>();

	const confirmBackModalRef = useRef<ModalRef>(null);

	const dispatch = useAppDispatch();

	const handleValidate = () => {
		dispatch(validateSubjectFromQuestions());

		handleSubmit();
	};

	const showConfirmBackModal = () => {
		confirmBackModalRef?.current?.open();
	};

	const hideConfirmBackModal = () => {
		confirmBackModalRef?.current?.close();
	};

	const handleConfirmBack = () => {
		resetForm({
			values: {
				...initialValues,
				journey: values.journey,
				projectUrl: values.projectUrl,
				projectName: values.projectName,
				projectType: values.projectType,
				estimatedNumberOfSubjects: values.estimatedNumberOfSubjects,
			},
		});

		setStep(Steps.STEP1);
	};

	return (
		<>
			<div className="project-section-btn">
				<Button
					value="Back"
					className="btn-secondary"
					handleClick={showConfirmBackModal}
				/>
				<Button
					value="Create"
					className="btn-primary"
					handleClick={handleValidate}
				/>
			</div>
			<Modal
				className="modal-info"
				ref={confirmBackModalRef}
				title="Are you sure you want to go back to step 1?"
				subtitle="Any info added on step 2 will not be saved."
			>
				<div className="org-modal-confirm">
					<Button
						className="btn-secondary"
						value="Go Back to Step 1"
						handleClick={handleConfirmBack}
					/>
					<Button
						value="Cancel"
						className="btn-primary"
						handleClick={hideConfirmBackModal}
					/>
				</div>
			</Modal>
		</>
	);
};
