import { Formik } from 'formik';
import { FC } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useLogout } from 'hooks/auth/useLogout';

import StudioService from 'api/services/StudioService';
import { studiosSelector } from 'store/studio';

import { closeAccountValidationSchema } from './validation';
import { CloseAccountForm } from './CloseAccountForm';

export interface ICloseAccountProps {
	hideCloseAccountModal: () => void;
}

export interface ICloseAccountValues {
	confirmationText: string;
	confirmationCheckbox: boolean;
}

export const CloseAccount: FC<ICloseAccountProps> = ({
	hideCloseAccountModal,
}) => {
	const studios = useAppSelector(studiosSelector);

	const logout = useLogout();

	const initialFormValues: ICloseAccountValues = {
		confirmationText: '',
		confirmationCheckbox: false,
	};

	const onSubmit = async () => {
		try {
			await StudioService.closeStudio();

			if (studios.length === 1) {
				await logout();
			} else {
				location.reload();
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Formik
			onSubmit={onSubmit}
			initialValues={initialFormValues}
			validationSchema={closeAccountValidationSchema}
		>
			<CloseAccountForm hideCloseAccountModal={hideCloseAccountModal} />
		</Formik>
	);
};
