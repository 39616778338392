import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';

import { setSubscription } from 'store/subscriptions';
import { setStudio } from 'store/studio';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';

export type Logout = () => Promise<void>;

export const useLogout = (): Logout => {
	const dispatch = useAppDispatch();
	const { instance } = useMsal();

	const logout = useCallback(async () => {
		try {
			dispatch(setStudio(null));
			dispatch(setSubscription(null));

			await instance.logoutRedirect();
		} catch (error) {
			console.log(error);
		}
	}, [instance]);

	return logout;
};
