import { useState, useEffect } from 'react';
import ReactConfetti from 'react-confetti';
import { useWindowSize } from 'usehooks-ts';

export const Confetti = () => {
	const { width, height } = useWindowSize();

	const drawStar = (ctx: CanvasRenderingContext2D) => {
		ctx.beginPath();
		const [outerRadius, innerRadius, points] = [10, 5, 5];

		for (let i = 0; i < points * 2; i++) {
			const angle = (Math.PI / points) * i;
			const radius = i % 2 === 0 ? outerRadius : innerRadius;
			ctx.lineTo(Math.cos(angle) * radius, Math.sin(angle) * radius);
		}

		ctx.closePath();
		ctx.fill();
	};

	const baseSource = (
		x: number,
		initialVelocityX: { min: number; max: number },
		drawShape?: typeof drawStar
	) => ({
		x,
		y: height,
		w: 0,
		h: height,
		initialVelocityX,
		drawShape,
	});

	const sources = [
		baseSource(width / 2, { min: -5, max: 5 }, drawStar),
		baseSource(width / 2, { min: -5, max: 5 }),
		baseSource(width / 8, { min: 0, max: 10 }, drawStar), //0
		baseSource(width / 8, { min: 0, max: 10 }), //0
		baseSource(width - width / 8, { min: 0, max: -10 }, drawStar), //width
		baseSource(width - width / 8, { min: 0, max: -10 }), //width
	];

	const [key, setKey] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setKey((prevKey) => prevKey + 1);
		}, 6000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div className="project-confetti">
			{sources.map((source, index) => (
				<ReactConfetti
					key={`${key}-${index}`}
					width={width}
					height={height}
					numberOfPieces={5000}
					recycle={false}
					gravity={0.1}
					colors={['#2C2E45', '#FEC52E', '#F15635', '#009BED']}
					initialVelocityY={{ min: 20, max: -20 }}
					confettiSource={source}
					drawShape={source.drawShape}
					tweenDuration={5000}
				/>
			))}
		</div>
	);
};
