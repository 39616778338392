import { utc } from 'moment';
import { FC } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { cardInfoSelector } from 'store/studioBilling';

export const BillingInfoList: FC = () => {
	const cardInfo = useAppSelector(cardInfoSelector);

	const expireDate = cardInfo && utc(cardInfo.expires).format('MM/YY');

	return (
		<ul className="accing-list">
			<li className="accing-item">
				Card Type: <span>{cardInfo?.cardType}</span>
			</li>
			<li className="accing-item">
				Card Number: <span>**** **** **** {cardInfo?.lastFour}</span>
			</li>
			<li className="accing-item">
				Expiration Date: <span>{expireDate}</span>
			</li>
		</ul>
	);
};
