import {
	FC,
	useState,
	useEffect,
	ChangeEvent,
	KeyboardEvent,
	SyntheticEvent,
} from 'react';

import { timeZonesSelectOptions } from 'constants/account/selectOptions/timeZonesSelectOptions';
import { phoneErrorMessage } from 'constants/general/validation/phoneE164USValidation';
import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import { statesSelectOptions } from 'constants/general/states/statesSelectOptions';
import { FIELDS_LENGTH } from 'constants/general/validation/fieldsLength';
import { invalidZipMessage } from 'constants/general/validation/zip';
import { VALID_FIELDS_LENGTH } from 'constants/auth/validation';
import { EnterCode } from 'constants/general/keyboardCodes';

import { useAsyncOptimizedSelect } from 'hooks/formControls/useAsyncOptimizedSelect';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { getFieldLengthMessage } from 'utils/validations/general/getFieldLengthMessage';
import { validateStringNumber } from 'utils/validations/general/validateStringNumber';
import { validateEmail } from 'utils/validations/general/validateEmail';
import {
	isValidPhone,
	convertUSPhoneToE164Format,
} from 'utils/validations/general/validatePhone';

import { IStudioContactInfo } from 'api/models/responses/studio/studioContactInfo';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import { TimeZones } from 'api/models/responses/studio/timeZones';
import StudioService from 'api/services/StudioService';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';

import { States } from 'types/ui/states';
import {
	setStudio,
	studioSelector,
	setStudioContactInfo,
	studioContactInfoSelector,
} from 'store/studio';

type StudioContactInfoErrors = Record<
	keyof Omit<IStudioContactInfo, 'state' | 'suite' | 'website'>,
	string
>;

const initialStudioContactInfoErrors: StudioContactInfoErrors = {
	zip: '',
	city: '',
	email: '',
	phone: '',
	street: '',
};

export const StudioContactInfo: FC = () => {
	const [studioContactInfoErrors, setStudioContactInfoErrors] =
		useState<StudioContactInfoErrors>(initialStudioContactInfoErrors);

	const [zip, setZip] = useState('');

	const studioContactInfo = useAppSelector(studioContactInfoSelector);
	const studio = useAppSelector(studioSelector);

	const dispatch = useAppDispatch();

	const {
		zip: zipError,
		city: cityError,
		email: emailError,
		phone: phoneError,
		street: streetError,
	} = studioContactInfoErrors;

	const updateStudioContactInfoErrors = (
		path: keyof StudioContactInfoErrors,
		error: string
	) => {
		setStudioContactInfoErrors((prevState) => ({
			...prevState,
			[path]: error,
		}));
	};

	const updateStudio = async (body: IPatchBody[]) => {
		try {
			const data = await StudioService.updateStudio(body);

			dispatch(setStudio(data));
		} catch (error) {
			console.log(error);
		}
	};

	const updateStudioContactInfo = async (
		path: keyof IStudioContactInfo,
		value: IStudioContactInfo[keyof IStudioContactInfo]
	) => {
		const updatedField: IPatchBody = {
			path,
			value,
			op: 'replace',
		};

		const body: IPatchBody[] = [updatedField];

		try {
			const data = await StudioService.updateStudioContactInfo(body);

			dispatch(setStudioContactInfo(data));
		} catch (error) {
			console.log(error);
		}
	};

	const handleChangeTimeZone = async (value: TimeZones) => {
		const updatedTimeZone: IPatchBody = {
			value,
			op: 'replace',
			path: 'timeZone',
		};

		const body: IPatchBody[] = [updatedTimeZone];

		await updateStudio(body);
	};

	const handleChangeEmail = (e: SyntheticEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;

		const emailValidationMessage = validateEmail(value);

		if (emailValidationMessage) {
			return updateStudioContactInfoErrors('email', emailValidationMessage);
		}

		void updateStudioContactInfo('email', value);
	};

	const clearEmailError = () => {
		if (!emailError) return;

		updateStudioContactInfoErrors('email', '');
	};

	const handleChangePhone = (e: SyntheticEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;

		if (!value) {
			return updateStudioContactInfoErrors('phone', requiredFieldMessage);
		}

		const E164PhoneFormat = convertUSPhoneToE164Format(value);
		const isValid = isValidPhone(E164PhoneFormat);

		if (!isValid) {
			return updateStudioContactInfoErrors('phone', phoneErrorMessage);
		}

		void updateStudioContactInfo('phone', value);
	};

	const clearPhoneError = () => {
		if (!phoneError) return;

		updateStudioContactInfoErrors('phone', '');
	};

	const handleChangeAddress = (e: SyntheticEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;

		if (!value) {
			return updateStudioContactInfoErrors('street', requiredFieldMessage);
		}

		if (value.length > FIELDS_LENGTH.LARGE_LENGTH) {
			return updateStudioContactInfoErrors(
				'street',
				getFieldLengthMessage(FIELDS_LENGTH.LARGE_LENGTH)
			);
		}

		void updateStudioContactInfo('street', value);
	};

	const clearAddressError = () => {
		if (!streetError) return;

		updateStudioContactInfoErrors('street', '');
	};

	const handleChangeCity = (e: SyntheticEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;

		if (!value) {
			return updateStudioContactInfoErrors('city', requiredFieldMessage);
		}

		if (value.length > FIELDS_LENGTH.MEDIUM_LENGTH) {
			return updateStudioContactInfoErrors(
				'city',
				getFieldLengthMessage(FIELDS_LENGTH.MEDIUM_LENGTH)
			);
		}

		void updateStudioContactInfo('city', value);
	};

	const clearCityError = () => {
		if (!cityError) return;

		updateStudioContactInfoErrors('city', '');
	};

	const handleChangeZipcode = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		const isValid = validateStringNumber(value, VALID_FIELDS_LENGTH.ZIP);

		if (!isValid) return;

		if (studioContactInfoErrors.zip) {
			updateStudioContactInfoErrors('zip', '');
		}

		setZip(value);
	};

	const updateZipCode = () => {
		if (!zip) {
			return updateStudioContactInfoErrors('zip', requiredFieldMessage);
		}

		if (zip.length !== VALID_FIELDS_LENGTH.ZIP) {
			return updateStudioContactInfoErrors('zip', invalidZipMessage);
		}

		void updateStudioContactInfo('zip', zip);
	};

	const handleKeydownZipcode = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.code === EnterCode) {
			updateZipCode();
		}
	};

	const handleChangeState = async (value: States) => {
		await updateStudioContactInfo('state', value);
	};

	useEffect(() => {
		const initialZip = studioContactInfo?.zip;

		if (!initialZip) return;

		setZip(initialZip);
	}, [studioContactInfo?.zip]);

	const timeZoneControls = useAsyncOptimizedSelect({
		initialValue: studio?.timeZone ?? null,
		updateSelectValue: handleChangeTimeZone,
	});

	const stateControls = useAsyncOptimizedSelect({
		updateSelectValue: handleChangeState,
		initialValue: studioContactInfo?.state ?? null,
	});

	return (
		<>
			<div className="acc-user-form acc-user-timezone">
				<div className="acc-user-select">
					<SelectComponent
						label="Time Zone"
						value={timeZoneControls.value}
						disabled={timeZoneControls.isPending}
						selectOptions={timeZonesSelectOptions}
						onChange={timeZoneControls.handleChange}
					/>
				</div>
				<InputGrid
					isLazy
					touched
					label="Email"
					required={false}
					error={emailError}
					placeholder="Email Address"
					clearError={clearEmailError}
					handleLazyChange={handleChangeEmail}
					defaultValue={studioContactInfo?.email}
				/>
				<InputGrid
					isLazy
					touched
					label="Phone"
					required={false}
					error={phoneError}
					placeholder="Phone Number"
					clearError={clearPhoneError}
					handleLazyChange={handleChangePhone}
					defaultValue={studioContactInfo?.phone}
				/>
			</div>
			<div className="acc-user-form acc-user-address">
				<InputGrid
					isLazy
					touched
					label="Address"
					required={false}
					error={streetError}
					placeholder="Address"
					clearError={clearAddressError}
					handleLazyChange={handleChangeAddress}
					defaultValue={studioContactInfo?.street}
				/>
				<InputGrid
					isLazy
					touched
					label="City"
					required={false}
					error={cityError}
					placeholder="City"
					clearError={clearCityError}
					handleLazyChange={handleChangeCity}
					defaultValue={studioContactInfo?.city}
				/>
				<div className="acc-user-select">
					<SelectComponent
						label="State"
						value={stateControls.value}
						disabled={stateControls.isPending}
						selectOptions={statesSelectOptions}
						onChange={stateControls.handleChange}
					/>
				</div>
				<InputGrid
					touched
					value={zip}
					label="Zipcode"
					required={false}
					error={zipError}
					placeholder="Zipcode"
					handleBlur={updateZipCode}
					handleChange={handleChangeZipcode}
					handleKeyDown={handleKeydownZipcode}
				/>
			</div>
		</>
	);
};
