import { object, string, ref, Schema } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	requiredStringSchema,
	mediumLengthRequiredStringSchema,
} from 'constants/general/validation/schemes/string';
import {
	maxRoutingLength,
	maxAccountingLength,
	minAccountingLength,
	maxAccountLengthError,
	maxRoutingLengthError,
	minAccountingLengthError,
} from 'constants/studioBilling/validation/bankACH';

import { w9InfoValidationSchema } from 'components/W9InfoForm/validation';

import { IBankAccountValues } from './types';

const confirmBankAccountNumberFieldValidation = string()
	.required(requiredFieldMessage)
	.equals(
		[ref<string>('bankAccountNumber')],
		'This field should be equal to Account #'
	);

const confirmBankRoutingNumberFieldValidation = string()
	.required(requiredFieldMessage)
	.equals(
		[ref<string>('bankRoutingNumber')],
		'This field should be equal to Routing #'
	);

export const bankACHValidationSchema = object().shape<
	Partial<Record<keyof IBankAccountValues, Schema>>
>({
	bankAccountName: mediumLengthRequiredStringSchema,
	bankAccountNumber: requiredStringSchema
		.min(minAccountingLength, minAccountingLengthError)
		.max(maxAccountingLength, maxAccountLengthError),
	confirmBankAccountNumber: confirmBankAccountNumberFieldValidation,
	bankRoutingNumber: requiredStringSchema.max(
		maxRoutingLength,
		maxRoutingLengthError
	),
	confirmBankRoutingNumber: confirmBankRoutingNumberFieldValidation,
});

export const getPaidFormValidationScheme = object().shape({
	...bankACHValidationSchema.fields,
	...w9InfoValidationSchema.fields,
});
