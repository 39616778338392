import { string } from 'yup';

import { requiredStringSchema } from './string';
import {
	emailRegEx,
	maxEmailLength,
	invalidEmailMessage,
	maxEmailLengthMessage,
} from '../email';

export const emailSchema = string()
	.matches(emailRegEx, invalidEmailMessage)
	.max(maxEmailLength, maxEmailLengthMessage);

export const emailRequiredSchema = emailSchema.concat(requiredStringSchema);
